<template>
  <div class="searchbar">
    <v-autocomplete
        :loading="loading"
        filled
        :search-input.sync="searchValue"
        dark
        dense
    >

      <template v-slot:no-data>

        <div class="search-content">

          <span v-if="items">
        <span class="ma-0" v-if="(items.length===0 && !searchValue) || !searchValue" >

        </span>
            </span>

          <span v-if="items">
        <p class="ma-0" v-if="(items.length===0 && searchValue)" >

        </p>
          </span>




        <div v-for="(item,index) in items" :key="index">
          <template v-if="searchValue" >
            <router-link :to="{name:'single',params:{id:item.id,title:originitems[index].title}}" custom v-slot="{ navigate , href }">
            <div @click="navigate" role="link" class="searchdiv py-1" >
              <div class="d-flex justify-space-between align-center">
                <a :href="href" role="link" style="font-size: 12px !important;" class="ma-0 grey--text" v-html="item.title">

                </a>

                <a :href="href" role="link">
                <img :src="$store.state.smallthumbnail + item.image" style="width: 42px !important;border-radius: 4px !important;" />
                </a>
              </div>
<!--              جستجوی "<span style="color: #9ce3ff; font-size: 14px">{{searchValue}}</span>"-->
            </div>
            </router-link>
          </template>
        </div>

        </div>

      </template>

      <template v-slot:append>
        <router-link :to="{name : 'posts',query : { str : searchValue },params:{id:0}}" custom v-slot="{ navigate , href }">
          <a @click="href" role="link" :href="href"><v-icon large style="cursor: pointer">mdi-magnify</v-icon></a>
        </router-link>
      </template>

      <template v-slot:label>
        جستجو در مهران مدیا
      </template>

    </v-autocomplete>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'App',

  data() {
    return {
      loading:false,
      items:[],
      originitems : [],
      searchValue:'',
    }
  },
  watch : {
    searchValue(newStr,oldStr){
      if(newStr!==oldStr){
        this.catitems=[];
        if(this.searchValue!=null){
          this.loading=true;
          axios.get(this.$store.state.api + 'livesearch', {params: {str: this.searchValue}}).then(response => {
            /*this.originitems = [];
            this.originitems=[...response.data.data];*/
            /*JSON.parse(JSON.stringify(response.data.data));*/
            this.originitems = JSON.parse(JSON.stringify(response.data.data)); // copy same array !!! har chi kardam dorost hesabi copy nashod

            let data = [];
            data = JSON.parse(JSON.stringify(response.data.data));

              for (let item in data){
                data[item].title = data[item].title.replace(this.searchValue, "<span style='background-color: #43656f'>"+ this.searchValue +"</span>");
              }

            this.items = data;


            this.loading=false;
          })
        }
      }

    }
  },
  created() {
    axios.get(this.$store.state.api + 'livesearch', {params: {str: this.searchValue,locationId : this.$store.state.locationId}}).then(response => {
      this.items = response.data.data;
    })
  },
  methods : {
    /*searchClick(id,title){
      this.$router.push({name:'single',params:{id:id,title:title}});
      this.searchValue='';
    }*/
  },
};
</script>


<style lang="scss" >

.searchdiv{
  cursor: pointer;
  color: grey;
  min-height: 50px;
  padding: 0 15px;
  font-size: 12px;
  background-color: #2b2b37;
  font-family: "Shabnam FD" !important;
}

.searchdiv:hover{
  background-color: #393949;
}

.v-list {
  padding: 0 !important;
  /*border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px;*/
}

.theme--light.v-list {
  background-color: #2b2b37 !important;
}

.search-content *{
  color: white;
}

.searchbar .v-input__append-inner {
  margin-top: 10px !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: transparent !important;
  border-radius: 20px !important;
  /*border-style: solid;
  border-width: thin 0 0 0;*/
}

.v-input__slot{
  border-radius: 10px !important;
}

.v-autocomplete__content.v-menu__content {
  width: 18% !important;
  max-height: 100% !important;
}

</style>
